import React from 'react'
import Gov from '../components/goverence/Index'

function Goverence() {
  return (
    <>
    <div className='mt-5 pt-3'>
    <Gov/>
    </div>
    </>
  )
}

export default Goverence