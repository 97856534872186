/** @format */

import React from "react";
import { SiMedium } from "react-icons/si";
import {
  FaReddit,
  FaGithub,
  FaTwitter,
  FaTelegram,
  FaBook,
  FaInstagram,
  FaFacebook,
} from "react-icons/fa";
import { BsEnvelope } from "react-icons/bs";
function Footer() {
  return (
    <div className='container-fluid footer-bg'>
      <div className='container'>
        <video playsInline autoPlay muted loop className='m-auto ' >
          <source src={process.env.REACT_APP_S3_URL +'/2lHCFZQ.mp4'} type='video/mp4' />
          Your browser does not support the video tag.
        </video>
        <div className='footer-ab-content'>
          <div className="col-md-12 text-center mb-3 ">
            <img src="assets/footer1.svg" alt="" className='footer-img' />
          </div>
          <div className='row'>
            <div className='col-md-4 col-rel text-center'>
              <img src='assets/footer.svg' alt='' className='mb-3' /> <br />
              <a href='mailto:hello@aisquare.io' className='text-light'>
                <BsEnvelope color='#7A74FF' /> hello@ai2.club
              </a>
            </div>
            <div className='col-md-2 col-6'>
            <h3>Contact us</h3>
            <ul className='list-unstyled'>
              <li><a href="https://twitter.com/squareAi_">Send feedback</a></li>
              <li><a href="hhttps://t.me/AI2_CLUB">Join Community</a></li>
              
             
            </ul>
            </div>
            <div className='col-md-2 col-6'>
            <h3>Learn more</h3>
            <ul className='list-unstyled'>
            <li><a href="https://chat.ai2.club">Getting started</a></li>
              <li><a href="https://earn.ai2.club">Staking</a></li>
              <li><a href="https://vote.ai2.club">Governance</a></li>
            </ul>
            </div>
            <div className='col-md-4'>
              <h3>Our community</h3>
              <a href='https://squareai.gitbook.io/square-ai' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaBook className='mx-2' />
              </a>
              {/* <a href='#In-progress' target='_blank' rel='noopener noreferrer'>
                {" "}
                <SiMedium className='mx-2' />
              </a> */}
              <a href='https://twitter.com/squareAi_' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaTwitter className='mx-2' />
              </a>
              <a href='https://t.me/square_Ai' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaTelegram className='mx-2' />
              </a>
              <a href='https://www.reddit.com/r/squareAi_/' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaReddit className='mx-2' />
              </a>
              {/* <a href='#In-progress' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaGithub className='mx-2' />
              </a> */}
              <a href='https://www.instagram.com/squareAi_/' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaInstagram className='mx-2' />
              </a>
              {/* <a href='#In-progress' target='_blank' rel='noopener noreferrer'>
                {" "}
                <FaFacebook className='mx-2' />
              </a> */}
            </div>
            <hr />
            <p className='text-center'>AI Square 2023 © All right reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
